import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tweet from "../components/tweet"
import { BORDER_RADIUS, COLORS, GRADIENT } from "../styles/constants"

const GenerateTweetsPage = () => {
  const [generatedTweets, setGeneratedTweets] = useState([]);
  const [textToParse, setTextToParse] = useState('');
  const [urlToScrape, setUrlToScrape] = useState('');

  function generateTweets() {
    fetch("/.netlify/functions/generate-tweets", {
      method: 'POST',
      body: JSON.stringify({
        text: textToParse,
        url: urlToScrape
      })
    })
      .then(response => response.json())
      .then(responseObj => {
        console.log(responseObj);
        setGeneratedTweets(responseObj.tweets);
      })
  }

  return (
    <Layout>
      <SEO title="Article 2 Tweets - Tweet Generation tool"/>
      <h1 className="beta-title" style={{ marginTop: "2rem" }}>Article 2 Tweets</h1>
      <p>Enter a link to an article that you would like <br/>to generate tweets from:</p>
      {/*<textarea value={textToParse} onChange={e => setTextToParse(e.target.value)}/>*/}
      <input value={urlToScrape} onChange={e => setUrlToScrape(e.target.value)} style={{
        borderRadius: "4px",
        height: "44px",
        width: "350px",
        fontSize: "18px",
        marginRight: "4px",
      }}/>
      <br/>
      <button onClick={generateTweets} style={{
        padding: ".5rem 1.8rem",
        marginTop: "5px",
        color: COLORS.lightWhite,
        fontWeight: 700,
        background: GRADIENT,
        borderRadius: BORDER_RADIUS,
        borderWidth: 0,
        cursor: "pointer",
      }}>
        Generate
      </button>

      <div style={{
        marginTop: generatedTweets.length > 0 ? "2rem":"0"
      }}>
        {generatedTweets.map((tweet, i) => (
          <Tweet props={tweet.data} key={tweet.id} />
        ))}
      </div>

      <div style={{
        marginBottom: generatedTweets.length > 0 ? "2rem":"9rem"
      }}>
      </div>
    </Layout>
  );
}

export default GenerateTweetsPage